import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import React, { Suspense, useState } from 'react';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Contexthandlerscontext_provider } from './Contexthandlerscontext';
import { Loggedincontext } from './Loggedincontext.js';
import { Routedispatcherprovider } from './Routedispatcher';
import './components/Website/Generalfiles/CSS_GENERAL/bootstrap.css';
import './trans.css';

const Websiterouter = React.lazy(() => import('./components/Website/Websiterouter'));

const App = (props) => {
    const [openModal, setopenModal] = useState(false);

    axios.interceptors.request.use(function (config) {
        const cookies = new Cookies();

        var defaultheaders = config.headers;
        var token = cookies.get('coas12312efaasasdafasdas32131asdsdssdfseedfgthy444555adsadsaqweasd123!@_#!@3123');
        if (token != undefined) {
            defaultheaders.Authorization = 'Bearer ' + token;
        }

        config.headers = defaultheaders;
        return config;
    });
    let history = useHistory();
    const { userloggedinfobjcontext, setuserloggedinfobjcontext, isloggedincontext, setisloggedincontext } = React.useContext(Loggedincontext);

    return (
        <Contexthandlerscontext_provider>
            <Router>
                <Routedispatcherprovider>
                    <div style={{ width: '100%', background: 'white' }}>
                        <Suspense
                            fallback={
                                <div
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        position: 'absolute',
                                        backgroundColor: 'white',
                                    }}
                                    class="row w-100 m-0 d-flex justify-content-center"
                                >
                                    <div class="col-lg-12 d-flex justify-content-center align-items-center"></div>
                                </div>
                            }
                        >
                            <GoogleOAuthProvider clientId="9814317436-bugitrqrfvg1uurclvo987lqcf9s0ccc.apps.googleusercontent.com">
                                <Websiterouter />
                            </GoogleOAuthProvider>
                            {/* <ComingSoon /> */}
                            {/* <CVcomponent /> */}
                            {/* <Route
                                render={({ location, match }) => {
                                    return (
                                        <TransitionGroup>
                                            <Switch key={location.key} location={location}>
                                                <Route
                                                    path="/"
                                                    render={() => {
                                                        return <Websiterouter />;
                                                    }}
                                                />
                                            </Switch>
                                        </TransitionGroup>
                                    );
                                }}
                            /> */}
                        </Suspense>
                    </div>
                </Routedispatcherprovider>
            </Router>
            <NotificationContainer />
            <ReactQueryDevtools initialIsOpen />
        </Contexthandlerscontext_provider>
    );
};

export default App;
