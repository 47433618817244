import React, { Component } from 'react';
import Cookies from 'universal-cookie';
export const LanguageContext = React.createContext();
export class LanguageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: {
                en: {
                    about: 'About',
                    company: 'Company',
                    officephotos: 'Office Photos',
                    viewall: 'View all',
                    ratings: 'Ratings',
                    reviews: 'Reviews',
                    contributeandEarnRewards: 'Contribute & Earn Rewards',
                    faqs: 'FAQs',
                    suggest: 'Suggest',
                    foundedin: 'Founded in',
                    ownership: 'Ownership',
                    egyptemployeecount: 'Egypt Employee count',
                    officelocations: 'Office Locations',
                    ceo: 'CEO',
                    founder: 'Founder',
                    typeofcompany: 'Type of Company',
                    natureofbusiness: 'Nature of Business',
                    companyemailid: 'Company Email ID',
                    companycontactno: 'Company Contact no.',
                    socialmediapresence: 'Social Media Presence',
                    website: 'Website',
                    requesthrcvreview: 'Review My CV',
                    keepthisanonymous: 'Keep this anonymous',
                    cancel: 'cancel',
                    comment: 'comment',
                    loginfirst: 'Login First',
                    submit: 'Submit',
                    pleasetryagain: 'please try again',
                    somethingwentwrong: 'Something went wrong',
                    askyourquestion: 'Ask your question',
                    getanswersfrom: 'Get answers from',
                    employees: 'employees',
                    postanonymously: 'Post anonymously',
                    postquestion: 'Post question',
                    salaries: 'Salaries',
                    benefitreviews: 'Benefit Reviews',
                    writeareview: 'Write a Review',
                    addsalary: 'Add Salary',
                    associatedcompanies: 'Associated Companies',
                    shareinterview: 'Share Interview Experience',
                    unfollow: 'Unfollow',
                    follow: 'Follow',
                    following: 'Following',
                    interviews: 'Interviews',
                    followers: 'Followers',
                    companiesin: 'Companies in',
                    companyname: 'Company Name',
                    salaryrating: 'Salary rating',
                    yrs: 'yrs',
                    compare: 'Compare',
                    vs: 'vs',
                    contributesalary: 'Contribute Salary',
                    addofficephotos: 'Add Office Photos',
                    shareyouroponion: 'Share your opinion!',
                    earncoins: 'Earn coins.',
                    department: 'Department',
                    mission: 'Mission',
                    primaryindustry: 'Primary Industry',
                    primaryindustrydescription: 'Primary industry refers to the industry from which a company drives its maximum revenue.',
                    otherindustry: 'Other Industry',
                    otherindustrydescription: 'Other industries refer to additional industries in which a company operates other than its primary industry.',
                    fulltime: 'Full Time',
                    parttime: 'Part Time',
                    contractual: 'Contractual',
                    intern: 'Intern',
                    freelancer: 'Freelancer',
                    local: 'local',
                    biglocal: 'big local',
                    startup: 'startup',
                    multinational: 'multinational',
                    clear: 'clear',
                    select: 'Select',
                    usersjobseekers: 'Users/Jobseekers',
                    corridortalks: 'CorridorTalks',
                    support: 'Support',
                    companies: 'Companies',
                    salarycalculator: 'Salary Calculator',
                    rewards: 'Rewards',
                    campusplacements: 'Campus Placements',
                    practicetest: 'Practice Test',
                    comparecompanies: 'Compare Companies',
                    aboutus: 'About Us',
                    emailus: 'Email Us',
                    privacy: 'Privacy',
                    grievances: 'Grievances',
                    termsofuse: 'Terms of Use',
                    summonsnotices: 'Summons/Notices',
                    communityguidelines: 'Community Guidelines',
                    givefeedback: 'Give Feedback',
                    footerdescription: 'Helping over 850k job seekers every month in choosing their right fit company',
                    usersmonth: 'Users/Month',
                    childcarefacility: 'Child care facility',
                    officecabshuttle: 'Office cab/shuttle',
                    cafeteria: 'Cafeteria',
                    freemeal: 'Free meal',
                    recreationalactivities: 'Recreational activities',
                    joininggoodies: 'Joining goodies',
                    giftsonfestivals: 'Gifts on festivals',
                    officegym: 'Office gym',
                    wfhsetup: 'WFH setup',
                    joiningbonus: 'Joining bonus',
                    performancebonus: 'Performance bonus',
                    relocationbonus: 'Relocation bonus',
                    stockoptions: 'Stock options',
                    mobilebillreimbursements: 'Mobile bill reimbursements',
                    retirementbenefits: 'Retirement benefits',
                    carlease: 'Car lease',
                    lifeinsurance: 'Life insurance',
                    healthinsurance: 'Health insurance',
                    annualhealthcheckup: 'Annual health checkup',
                    mentalhealthwellbeing: 'Mental health wellbeing',
                    gymmembership: 'Gym membership',
                    internationalonsiteexposure: 'International/On-site exposure',
                    jobsoftskilltraining: 'Job/Soft skill training',
                    rewardsandrecognition: 'Rewards & recognition',
                    coursereimbursements: 'Course reimbursements',
                    professionaldegreeassistance: 'Professional degree assistance',
                    maternityleaves: 'Maternity leaves',
                    annualleaves: 'Annual leaves',
                    paternityleaves: 'Paternity leaves',
                    careerbreaksabbatical: 'Career break/Sabbatical',
                    rateoverallbenefits: 'Rate the overall perks and benefits at your company',
                    whatisworkpolicy: 'What is the work policy at your company?',
                    totalworkexperience: 'Total work experience',
                    whatbenefitsdoyoulike: 'Which benefits you like the most at your company?',
                    whatbenefitsshoudbeimproved: 'What benefits should be improved in your company?',
                    pleaseloginfirst: 'Please Login first',
                    submitanonymously: 'Submit Anonymously',
                    rateyourcompanyanonymously: 'Rate your company, anonymously!',
                    shareyourinterviewquestions: 'Share your interview questions & help people get their dream job!',
                    shareyoursalaryanonymously: 'Share your salary, anonymously!',
                    areyoupaidenough: 'Are you paid enough?',
                    yourvoicemattersmakeitheard: 'Your voice matters, make it heard.',
                    findoutwhereyoustandamongyourpeers: 'Find out where you stand among your peers.',
                    howdidyougetthisinterview: 'How did you get this interview?',
                    jobportal: 'Job Portal',
                    campusplacement: 'Campus Placement',
                    referral: 'Referral',
                    walkin: 'Walk-in',
                    companywebsite: 'Company Website',
                    recruitmentconsultant: 'Recruitment Consultant',
                    newspapaperad: 'Newspaper Ad',
                    jobfair: 'Job Fair',
                    approachedbycompany: 'Approached by Company',
                    whenwasthisinterview: 'When was this interview?',
                    lessthanamonth: 'Less than a month',
                    oneetosixmonthsago: '1-6 months ago',
                    sixtotwelvemonthsago: '6-12 months ago',
                    morethanayearago: 'More than a year ago',
                    howlongwastheinterviewprocess: 'How long was the interview process?',
                    lessthantwoweeks: 'Less than 2 weeks',
                    twotofour: '2-4 weeks',
                    fourtosixweeks: '4-6 weeks',
                    sixtoeightweeks: '6-8 weeks',
                    morethaneightweeks: 'More than 8 weeks',
                    howdifficultwastheinterview: 'How difficult was the interview?',
                    easy: 'Easy',
                    moderate: 'Moderate',
                    hard: 'Hard',
                    didyougetanoffer: 'Did you get an offer?',
                    yesijoined: 'Yes, I joined the company',
                    yesbutideclinedtheoffer: 'Yes, but I declined the offer',
                    noididntrecieveanoffer: "No, I didn't receive an offer",
                    didnthearbackfromcompany: "I didn't hear back from the company",
                    success: 'Success',
                    wastherearesumescreeningbeforetheinterview: 'Was there a resume screening before the interview?',
                    yes: 'Yes',
                    no: 'No',
                    whatwasround: 'What was round',
                    sharedetails: 'Share details',
                    question: 'Question',
                    answer: 'Answer',
                    shareadviceforjobseekers: 'Share advice for job seekers',
                    shareyourtips: 'Share your tips',
                    rateoverallinterviewexperience: 'Rate overall interview experience',
                    terrible: 'Terrible',
                    poor: 'poor',
                    average: 'Average',
                    good: 'Good',
                    excellent: 'Excellent',
                    keepanonymous: 'Keep Anonymous',
                    confirmdelete: 'Confirm Delete',
                    areyousureyouwanttodeleterounddetails: 'Are you sure you want to delete round details?',
                    salary: 'Salary',
                    senioritylevel: 'Seniority level',
                    yearsofexperience: 'Years of experience',
                    letsfindout: "Let's Find out",
                    edit: 'Edit',
                    at: 'at',
                    experience: 'Experience',
                    recalculate: 'Recalculate',
                    youarepaid: 'You are paid',
                    marketvalue: 'market value',
                    yourmarketvalue: 'Your net market value',
                    year: 'year',
                    yourcurrentsalary: 'Your net current salary',
                    uploadphotos: 'Upload Photos',
                    or: 'or',
                    draganddrop: 'Drag & drop files from desktop',
                    photoselected: ' photo selected',
                    whatisthisphotoabout: 'What is this photo about',
                    officelocation: 'Office location',
                    pleasecompletemissingfields: 'Please complete the missing fields',
                    next: 'Next',
                    submitphotos: 'Submit Photos',
                    tipsandguidelines: 'Tips and guidelines',
                    overallrating: 'Overall Rating',
                    worklifebalance: 'Work-Life Balance',
                    salaryandbenefits: 'Salary and Benefits',
                    promotionsappraisal: 'Promotions / Appraisal',
                    jobsecurity: 'Job Security',
                    skilldevelopmentlearning: 'Skill Development / Learning',
                    worksatisfaction: 'Work Satisfaction',
                    companyculture: 'Company Culture & Employee Engagement',
                    selectmonth: 'Month',
                    selectyear: 'Year',
                    yournamewillremainconfidential: 'Your name will remain 100% confidential',
                    doyoucurrentlyworkhere: 'Do you currently work here?',
                    startedworkingon: 'Started working on',
                    employmentendedon: 'Employment ended on',
                    ratethecompanyonthefollowingcriteria: 'Rate the company on the following criteria',
                    whatdoyoulikeaboutworkingatyourcompany: 'What do you like about working at your company?',
                    likes: 'Likes',
                    whatdoyoudislikeaboutworkingatyourcompany: 'What do you dislike about working at your company?',
                    dislikes: 'Dislikes',
                    doesyourjobrequireyoutotravelforwork: 'Does your job require you to travel for work?',
                    whatareyourworkingdays: 'What are your working days?',
                    mondaytofriday: 'Monday to Friday',
                    mondaytosaturday: 'Monday to Saturday',
                    alternatesaturdaysoff: 'Alternate Saturdays off',
                    rotationalshift: 'Rotational Shift',
                    alldays: 'All 7 days',
                    other: 'Other',
                    specifytheexactworkingdays: 'Specify the exact working days',
                    areyourworkinghoursstrictorflexible: 'Are your working hours strict or flexible?',
                    strict: 'Strict',
                    flexiable: 'Flexible',
                    whatareyourshifttimings: 'What are your shift timings?',
                    starttime: 'Start time',
                    endtime: 'End time',
                    gender: 'Gender',
                    male: 'Male',
                    female: 'Female',
                    permanentworkfromhome: 'Permanent work from home',
                    workingfromoffice: 'Working from office',
                    hybrid: 'Hybrid (working from office & home)',
                    unclearatthemoment: 'Unclear at the moment',
                    enterin: 'Enter in %',
                    yearofincrement: 'Year of increment',
                    employmenttype: 'Employment Type',
                    interviewroundsandprocess: 'Interview Rounds and Process',
                    interviewpreparationtips: 'Interview Preparation Tips',
                    interviewquestionsprocessandtips: 'Interview Questions, Process, and Tips',
                    popularjobprofiles: 'Popular Job Profiles',
                    designationjob: 'Job Title',
                    questionsandanswers: 'Questions and Answers',
                    seequestionsabout: 'See questions about',
                    more: 'More',
                    moreanswers: 'more answers',
                    view: 'View',
                    promotions: 'Promotions',
                    categoryratings: 'Category Ratings',
                    explorereviewsbydepartment: 'Explore Reviews by Department',
                    topmentions: 'Top Mentions',
                    inreviews: 'in Reviews',
                    similarcompanies: 'Similar Companies',
                    difficultylevel: 'Difficulty Level',
                    duration: 'Duration',
                    result: 'Result',
                    round: 'Round',
                    readmore: 'Read More',
                    workdetails: 'Work Details',
                    workpolicy: 'Work Policy',
                    workdays: 'Work Days',
                    workrelatedtravel: 'Work related travel',
                    load: 'Load',
                    off: 'OFF',
                    claimfor: 'Claim for',
                    coins: 'Coins',
                    expiringsoon: 'Expiring soon!',
                    salariesinegypt: 'Salaries in Egypt',
                    ratedby: 'Rated by',
                    employeesforsalaryandbenefits: ' Employees for salary and benefits',
                    topdepartmentsat: 'Top Departments at',
                    designation: 'Designation',
                    avarageannualsalary: 'Average Annual Salary',
                    low: 'Low',
                    highest: 'Highest',
                    interviewsquestions: 'Interviews Questions',
                    aboutcompany: 'About Company',
                    whichcompanyordesignation: 'Which company?',
                    search: 'Search',
                    jobsdesignations: 'Jobs/ designations',
                    searchby: 'Search by',
                    discoverbestplacestowork: 'Discover best places to work',
                    bringyourworkplacetolife: 'Bring your workplace to life',
                    addcompanybenefits: 'Add Company Benefits',
                    highlightyourcompanyperks: "Highlight your company's perks",
                    companyreviews: 'Company reviews',
                    readreviews: 'Read reviews for companies',
                    rateyourformercompany: 'Rate your former or current company',
                    browsesalaries: 'Browse salaries',
                    discoversalaries: 'Discover salaries for companies',
                    areyoupaidfairly: 'Are you paid fairly',
                    checkyourmarketvalue: 'Check your market value',
                    shareyoursalary: 'Share your salary',
                    helpotherjobseekers: 'Help other jobseekers',
                    companyinterviews: 'Company interviews',
                    readinterviewscompanies: 'Read interviews for companies',
                    addasalary: 'Add a Salary',
                    shareaninterview: 'Share an Interview',
                    contributetohelpmillions: 'Contribute to help millions!',
                    contribute: 'Contribute',
                    login: 'Login',
                    profile: 'Profile',
                    manageprefrences: 'Manage preferences',
                    logout: 'Logout',
                    edityourprofiledetails: 'Edit your profile details',
                    areyouastudentorworkingprofessional: 'Are you a student or working professional?',
                    studentNeverworked: 'Student/Never worked',
                    iamastudenthaveneverworked: 'I am a student / I have never worked',
                    workingprofessional: 'Working Professional',
                    iamworkinghaveworkedbefore: 'I am working / I have worked before',
                    yourworkexperience: 'Your work experience',
                    save: 'Save',
                    coming: 'Coming',
                    soon: 'soon',
                    location: 'Location',
                    industry: 'Industry',
                    companysize: 'Company size(Employees)',
                    companytype: 'Company type',
                    listofcompaniesinegypt: 'List of companies in Egypt',
                    uniquecompaniesfound: 'unique companies found',
                    companyrating: 'company rating',
                    andabove: '& above',
                    joinegyptslargestcommunity: 'Join Egypt’s largest community',
                    email: 'Email',
                    password: 'Password',
                    firstname: 'First Name',
                    lastname: 'Last Name',
                    confirmpassword: 'Confirm Password',
                    passwordsdontmatch: "Passwords don't match",
                    signup: 'Sign-up',
                    workfromhome: 'Work From Home',
                    gymanasium: 'Gymnasium',
                    softskillstraining: 'Soft Skills Training',
                    freefood: 'Free Food',
                    freetransport: 'Free Transport',
                    childcare: 'Child care',
                    educationassistance: 'Education Assistance',
                    internationalrelocation: 'International Relocation',
                    jobtraining: 'Job Training',
                    teamoutings: 'Team Outings',
                    readcompanyreviewstoavoidcareermistakes: 'Read company reviews to make better career choices',
                    explorecompanyreviews: 'Explore Company Reviews',
                    exploresalariesofmorethancompanies: "Explore company salaries and check if you're paid fairly!",
                    exploresalaries: 'Explore Salaries',
                    areyoupaidfairly: 'Are you paid fairly',
                    companywiseinterviewquestions: 'Company-wise Interview Experiences',
                    shareinterviewquestions: 'Share Interview Questions',
                    egyptsnoplatform: "Egypt's No. 1 platform",
                    for: 'for',
                    salaryinsights: 'Employer Insights',
                    popularsearches: 'Popular Searches',
                    findcompaniesthatdeserveyou: 'Find companies that you deserve!',
                    prepareyournextinterviewwithcorridortalks: 'Prepare your next interview with CorridorTalks',
                    egyptsinterviewpreparationplatform: 'Egypts #1 interview experience sharing platform',
                    withKcompanies: 'with companies',
                    crazybuttrueinterviewquestionsfromjobprofiles: 'Go prepared with real interview experiences from the community!',
                    preparefrominterviewquestions: ' Prepare from interview questions',
                    shineatyourtechnicalinterviewswithlakhinterviewquestionssparklogo: 'Browse real interview experiences for different roles and various companies!',
                    browsecompaniesbylocation: 'Browse Companies by Location',
                    browsecompaniesbyindustries: ' Browse Companies by Industries',
                    browsebycompanysize: 'Browse by Company Size',
                    basedonnumberofemployees: 'based on number of employees',
                    popularcompanies: 'Popular Companies',
                    getrewardsforhelpingothers: ' Get rewards for helping others',
                    yourcoinsbalance: 'Your Coins Balance',
                    claimed: 'Claimed',
                    coinshistory: 'Coins History',
                    all: 'All',
                    earned: 'Earned',
                    spent: 'Spent',
                    contributedofficephotos: 'Contributed office Photos',
                    contributedinterview: 'Contributed interview',
                    contributedreview: 'Contributed review',
                    contributedbenefits: 'Contributed Benefits',
                    claimedreward: 'Claimed Reward',
                    howdocoinswork: 'How do Coins work?',
                    sharethegoodness: 'Share the happiness!',
                    price: 'Price',
                    validtill: 'Valid till',
                    stepstoredeem: 'Steps to Redeem',
                    tandc: 'T&C',
                    earntoredeem: 'Earn to Redeem',
                    yousave: 'You Save',
                    doyouwanttoproceedwiththetransaction: 'Do you want to proceed with the transaction?',
                    coinswillbedebitedfromyouraccount: 'coins will be debited from your account',
                    proceed: 'Proceed',
                    searchandcomparesalaries: 'Search and Compare Salaries',
                    comparesalariesbydesignationsandexperience: 'Compare salaries by designations and experience.',
                    salarycomparison: 'Salary Comparison',
                    comparesalariesbetweenhighestpayingcompanies: 'Compare salaries between highest paying companies',
                    getrelevantsalaryresultsbyselectingyourdepartment: ' Get relevant salary results by selecting your department',
                    salariesbycompany: 'Salaries by Company',
                    enternewpassword: 'Enter new password',
                    enterannualsalaryexpectation: 'Enter annual salary expectation',
                    preferredjobprofiles: 'Preferred job profiles',
                    addyourpreferrredjobprofilestogetrelevantjobsuggestions: 'Add your preferred job profiles to get relevant job suggestions',
                    preferredindustry: 'Preferred industry',
                    addyourpreferrredindustrieswhereyouwanttowork: 'Add your preferred industries where you want to work',
                    salaryexpectation: 'Salary Expectation',
                    addyourminimumsalaryexpectationtogetrelevantjobsuggestions: 'Add your minimum salary expectation to get relevant job suggestions',
                    yourskills: 'Your skills',
                    addyourskillstogetrelevantjobsuggestions: 'Add your skills to get relevant job suggestions',
                    preferredlocations: 'Preferred locations',
                    addyourpreferrredlocationstogetrelevantjobsuggestions: 'Add your preferred locations to get relevant job suggestions',
                    manageyourpreferences: 'Manage your preferences',
                    updateyourpreferencestogetapersonalisedexperienceoncorridortalks: 'Update your preferences to get a personalized experience on CorridorTalks.',
                    add: 'Add',
                    enteryourpreferredindustry: 'Enter your preferred industry',
                    enteryourskills: 'Enter your skills',
                    enteryourpreferredlocations: 'Enter your preferred locations',
                    enteryourpreferredjobprofiles: 'Enter your preferred job profiles',
                    wantanenhancedexperience: ' Want an enhanced experience?',
                    clickhere: 'click here',
                    companiesyoufollow: 'Companies you follow',
                    youhaventfollowedanycompanysofar: "you haven't followed any company so far",
                    userinformation: ' User Information',
                    submittedon: 'submitted on',
                    postedon: 'posted on',
                    byyou: 'by you',
                    editprofileinfo: 'Edit Profile Info',
                    rateyourcompany: 'Rate your company',
                    youhaventwrittenanycompanyreviewsofar: "You haven't written any company review so far",
                    interviewexperience: 'Interview Experience',
                    submitinterviewquestions: 'Submit interview questions',
                    youhaventwrittenanyinterviewreviewsofar: "You haven't written any interview review so far",
                    salariesshared: 'Salaries shared',
                    submitasalary: 'Submit a salary',
                    youhaventsubmittedanysalarysofar: "You haven't submitted any salary so far",
                    companyphotos: 'Company Photos',
                    youhaventsubmittedanycompanyphotosofar: "You haven't submitted any company photo so far",
                    addbenefits: 'Add Benefits',
                    companybenefits: 'Company Benefits',
                    youhaventsubmittedanycompanybenefitssofar: "You haven't submitted any company benefits so far",
                    contributionsgiven: 'CONTRIBUTIONS GIVEN',
                    review: 'Review',
                    inerview: 'Interview',
                    salary: 'Salary',
                    averagesalary: 'Average Salary',
                    buildyourcv: 'Build your CV',
                    contributeyourinterviewquestions: 'Contribute your interview questions',
                    shareyoucompanybenefits: 'Share your company benefits',
                    helpotherjobseekersbysharingyoursalary: 'Help others by sharing your salary',
                    reviewtitle: 'Review Title',
                    personaldevelopment: 'Personal Development',
                    managementandleadershipcapabilities: 'Management and Leadership Capabilities',
                    diversityandinclusion: 'Diversity & Inclusion',
                    careergrowth: 'Career Growth',
                    whatarethestrengths: 'What are the strengths',
                    whatarethedevelopmentareas: 'What are the development areas',
                    whatrecommendationswouldyougivetothemanagement: 'What recommendations would you give to the management',
                    wouldyourecommendthiscompanytoafriend: 'Would you recommend this company to a friend',
                    areyouconsideringleaving: 'Are you considering leaving',
                    organisationallevel: 'Organisational Level',
                    intern: 'Intern',
                    nonmanagement: 'Non-management',
                    management: 'Management',
                    seniormanagement: 'Senior management',
                    director: 'Director',
                    wasanofferprovided: 'Was an offer provided',
                    interviewtitle: 'Interview experience title',
                    ratetherecruitmentprocessinthebelowcategories: 'Rate the recruitment process in the below categories',
                    employerbrandrepresentation: 'Employer brand representation',
                    interviewersquestionsandstyle: 'Interviewers questions and style',
                    lengthoftherecruitmentprocess: 'Length of the recruitment process',
                    courtesyandprofessionalismduringrecruitmentprocess: 'Courtesy and professionalism during recruitment process',
                    areyouconsideringreapplyingagain: 'Are you considering re-applying again?',
                    employmentstatus: 'Employment Status',
                    companycultureemployeeengagement: 'Company Culture & Employee Engagement',
                    worklifebalanceandoverallwellbeing: 'Work-Life Balance and Overall Wellbeing',
                    pleaselogintocheckyourredeemedrewards: 'Please login to check your redeemed rewards.',
                    loginsignup: 'Login/Signup',
                    guest: 'Guest',
                    bonusscheme: 'Bonus scheme',
                    fixedsalary: 'Fixed Salary',
                    whichyearssalaryisit: 'Which year’s salary is it?',
                    lastincrement: 'Last salary increase',
                    aretherestockoptions: 'Are there stock options?',
                    howmanyshares: 'How many shares?',
                    totalyearsofexperience: 'Total years of experience',
                    yearsofexperienceinthissenioritylevel: 'Years of experience in this seniority level',
                    interviewrounds: 'Interview Rounds',
                    interviewexperience: 'Interview experience',
                    monthlysalary: 'Monthly Net Salary (EGP)',
                    notaddedyet: 'Not Added yet',
                    searchcompanyreviewsratings: 'Search Company Reviews & Ratings',
                    readwhatemployeesaresayingaboutcompanies: 'Read what employees are saying about companies.',
                    share: 'Share',
                    viewcomments: 'view comments',
                    hidecomments: 'hide comments',
                    benefits: 'Benefits',
                    qanda: 'Q & A',
                    photos: 'Photos',
                    personalinformation: 'Personal Information',
                    name: 'Name',
                    country: 'Country',
                    phonenumber: 'Phone number',
                    socialmedialink: 'Socialmedia link',
                    careersummary: 'Career summary',
                    education: 'Education',
                    schoolname: 'School name',
                    college: 'College',
                    major: 'Major',
                    gpa: 'GPA',
                    doyoucurrentlystudyhere: 'Do you currently study here?',
                    startedon: 'Started on',
                    endedon: 'Ended on',
                    workexperiencecareerachievements: 'Work experience & career achievements',
                    title: 'Title',
                    job: 'Job',
                    summaryofasksandachievements: 'Summary of tasks and achievements',
                    entityname: 'Entity name',
                    trainingtitle: 'Training title',
                    professionaltraining: 'Professional training',
                    training: 'Training',
                    communityservice: 'Community service',
                    summaryofdonetasks: 'Summary of done tasks',
                    date: 'Date',
                    internshipsandfellowships: 'Internships and fellowships',
                    internship: 'Internship',
                    position: 'Position',
                    tasksdone: 'Tasks done',
                    enterskill: 'Enter skill',
                    languages: 'Languages',
                    youhavenocvsyet: 'You have not claimed this reward yet - contribute and claim now to build your resume in an easy and professional way!',
                    buildyourcvnow: 'Build your CV now',
                    cvtitle: 'CV title',
                    buildanotercv: 'Build another CV',
                    delete: 'Delete',
                    show: 'Show',
                    month: 'Month',
                    strengths: 'Strengths',
                    developmentareas: 'Development areas',
                    monthlysalary: 'Monthly Net Salary (EGP)',
                    wedonthaveinfoaboutthiscompanyyet: "We don't have info about this company yet",
                    home: 'Home',
                    browsecompanyreviews: 'Browse company reviews',
                    browsecompanyinterviews: 'Browse company interviews',
                    discovercompanies: 'Discover companies',
                    languageproficiencylevel: 'Language proficiency level',
                    language: 'Language',
                    beginner: 'Beginner',
                    intermediate: 'Intermediate',
                    advanced: 'Advanced',
                    careergrowth: 'Career Growth',
                    native: 'Native',
                    download: 'Download',
                    tasks: 'Tasks',
                    task: 'Task',
                    extracurricularactivities: 'Extracurricular activities',
                    areyoureferredbyanyofourinterns: 'Do you have a referral code?',
                    refcode: 'Ref Code',
                    personaldevlopement: 'Personal Development',
                    infounavailable: 'Info unavailable',
                    addanswer: 'Add answer',
                    successcontribution: ' Thank you for your submission, your contribution is being reviewed and should be approved very shortly!',
                    recommendedtofriend: 'would recommend to friends',
                    optional: 'optional',
                    alldepartments: 'All departments',
                    followus: 'Follow us',
                    rejectedbenefits: 'Your benefit contribution has been rejected',
                    acceptedbenefits: 'Your benefit contribution has been accepted',
                    pendingbenefits: 'Your benefit contribution is pending',
                    rejectedsalary: 'Your salary contribution has been rejected',
                    acceptedsalary: 'Your salary contribution has been accepted',
                    pendingsalary: 'Your salary contribution is pending',
                    sharetheperksandbenefitsofyourcompany: 'Share the perks and benefits of your company',
                    youhavenotclaimedthisrewardandclaimnowtohaveyourresumereviewedbyprofessionals:
                        'You have not claimed this reward yet - contribute and claim now to have your resume reviewed by HR professionals!',
                    requesthrReviewforyourcv: 'Request HR Review For Your CV',
                    notifications: 'Notifications',
                    rejectedreview: 'Your review contribution has been rejected',
                    acceptedreview: 'Your review contribution has been accepted',
                    pendingreview: 'Your review contribution is pending',
                    rejectedinterview: 'Your interview contribution has been rejected',
                    acceptedinterview: 'Your interview contribution has been accepted',
                    pendinginterview: 'Your interview contribution is pending',
                },

                ar: {
                    personaldevlopement: 'Personal Development',
                    careergrowth: 'Career Growth',
                    about: 'عن الشركة',
                    company: 'الشركة',
                    officephotos: 'صور المكتب',
                    viewall: 'مشاهدة الكل',
                    ratings: 'التقييمات',
                    reviews: 'المراجعات',
                    contributeandEarnRewards: 'ساهم واكسب المكافآت',
                    faqs: 'أسئلة وأجوبة شائعة',
                    suggest: 'اقترح',
                    foundedin: 'أسس في',
                    ownership: 'ملكية',
                    egyptemployeecount: 'عدد موظف مصر',
                    officelocations: 'واقع المكاتب',
                    ceo: 'المدير التنفيذي',
                    founder: 'مؤسس',
                    typeofcompany: 'نوع الشركة',
                    natureofbusiness: 'طبيعة العمل',
                    companyemailid: 'البريد الإلكتروني للشركة',
                    companycontactno: 'رقم الشركة',
                    socialmediapresence: 'التواجد على وسائل التواصل الاجتماعي',
                    website: 'موقع إلكتروني',
                    requesthrcvreview: 'مراجعة سيرتي الذاتية',
                    keepthisanonymous: 'ابق هذا مجهول',
                    cancel: 'الغي',
                    comment: 'تعليق',
                    loginfirst: 'سجل الدخول أولا',
                    submit: 'إرسال',
                    pleasetryagain: 'حاول مرة اخرى',
                    somethingwentwrong: 'هناك خطأ ما',
                    askyourquestion: 'اسأل سؤالك',
                    getanswersfrom: 'احصل على إجابات من',
                    employees: 'موظفين',
                    postanonymously: 'انشر بشكل مجهول',
                    postquestion: 'انشر السؤال',
                    salaries: 'الرواتب',
                    benefitreviews: 'مراجعات الفوائد',
                    writeareview: 'أكتب مراجعة',
                    addsalary: 'إضافة الراتب',
                    associatedcompanies: 'الشركات ذات الصلة',
                    shareinterview: 'شارك تجربة المقابلة',
                    unfollow: 'الغاء المتابعة',
                    follow: 'تابع',
                    following: 'متابع',
                    interviews: 'المقابلات',
                    followers: 'متابعون',
                    companiesin: 'الشركات في',
                    companyname: 'اسم الشركة',
                    salaryrating: 'تصنيف الراتب',
                    yrs: 'سنوات',
                    compare: 'مقارنة',
                    vs: 'ضد',
                    contributesalary: 'المساهمة بالراتب',
                    addofficephotos: 'أضف صور المكتب',
                    shareyouroponion: 'مشاركة رأيك!',
                    earncoins: 'كسب النقود.',
                    department: 'قسم',
                    mission: 'مهمة',
                    primaryindustry: 'الصناعة الأساسية',
                    primaryindustrydescription: 'تشير الصناعة الأساسية إلى الصناعة التي تحقق منها الشركة أقصى إيرادات لها.',
                    otherindustry: 'صناعة أخرى',
                    otherindustrydescription: 'تشير الصناعات الأخرى إلى الصناعات الإضافية التي تعمل فيها الشركة بخلاف صناعتها الأساسية.',
                    fulltime: 'دوام كامل',
                    parttime: 'دوام جزئى',
                    contractual: 'تعاقدي',
                    intern: 'المتدرب',
                    freelancer: 'مستقل',
                    local: 'محلي',
                    biglocal: 'محلي كبير',
                    startup: 'شركة ناشئة',
                    multinational: 'شركة متعددة الجنسيات',
                    clear: 'مسح',
                    select: 'اختيار',
                    usersjobseekers: 'المستخدمون / الباحثون عن عمل',
                    corridortalks: 'CorridorTalks',
                    support: 'الدعم',
                    companies: 'الشركات',
                    salarycalculator: 'حاسبة الراتب',
                    rewards: 'المكافآت',
                    campusplacements: 'مواضع الحرم الجامعي',
                    practicetest: 'ممارسة الاختبار',
                    comparecompanies: 'قارن بين الشركات',
                    aboutus: 'معلومات عنا',
                    emailus: 'راسلنا',
                    privacy: 'خصوصية',
                    grievances: 'المظالم',
                    termsofuse: 'شروط الاستخدام',
                    summonsnotices: 'الاستدعاء / الإخطارات',
                    communityguidelines: 'إرشادات المجتمع',
                    givefeedback: 'عطاء ردود الفعل',
                    footerdescription: 'Helping over 850k job seekers every month in choosing their right fit company',
                    usersmonth: 'المستخدمون / الشهر',
                    childcarefacility: 'مرفق رعاية الأطفال',
                    officecabshuttle: 'كابينة مكتب / مكوك',
                    cafeteria: 'كافيتريا',
                    freemeal: 'وجبة مجانية',
                    recreationalactivities: 'الأنشطة الترفيهية',
                    joininggoodies: 'الانضمام إلى الأشياء الجيدة',
                    giftsonfestivals: 'هدايا في الاحتفالات',
                    officegym: 'الصالة الرياضية',
                    wfhsetup: 'إعداد WFH',
                    joiningbonus: 'مكافأة الانضمام',
                    performancebonus: 'مكافأة الأداء',
                    relocationbonus: 'مكافأة الانتقال',
                    stockoptions: 'خيارات الأسهم',
                    mobilebillreimbursements: 'سداد فاتورة المحمول',
                    retirementbenefits: 'استحقاقات التقاعد',
                    carlease: 'تأجير سيارة',
                    lifeinsurance: 'التأمين على الحياة',
                    healthinsurance: 'تأمين صحي',
                    annualhealthcheckup: 'فحص طبي سنوي',
                    mentalhealthwellbeing: 'افية الصحة العقلية',
                    gymmembership: 'عضوية الصالة الرياضية',
                    internationalonsiteexposure: 'التعرض الدولي / في الموقع',
                    jobsoftskilltraining: 'التدريب على الوظائف / المهارات الشخصية',
                    rewardsandrecognition: 'المكافآت والتقدير',
                    coursereimbursements: 'سداد تكاليف الدورة',
                    professionaldegreeassistance: 'مساعدة الدرجة المهنية',
                    maternityleaves: 'إجازة الأمومة',
                    annualleaves: 'الإجازات السنوية',
                    paternityleaves: 'إجازة الأبوة',
                    careerbreaksabbatical: 'استراحة مهنية / تفرغ',
                    rateoverallbenefits: 'قيِّم الامتيازات والفوائد الإجمالية في شركتك',
                    whatisworkpolicy: 'ما هي سياسة العمل في شركتك؟',
                    totalworkexperience: 'إجمالي خبرة العمل',
                    whatbenefitsdoyoulike: 'ما هي الفوائد التي تعجبك أكثر في شركتك؟',
                    whatbenefitsshoudbeimproved: 'ما الفوائد التي يجب تحسينها في شركتك؟',
                    pleaseloginfirst: 'الرجاء تسجيل الدخول أولا',
                    submitanonymously: 'إرسال مجهول',
                    rateyourcompanyanonymously: 'قيم شركتك دون الكشف عن هويتك!',
                    shareyourinterviewquestions: 'شارك أسئلة المقابلة الخاصة بك وساعد الناس في الحصول على وظيفة أحلامهم!',
                    shareyoursalaryanonymously: 'شارك راتبك دون الكشف عن هويتك!',
                    areyoupaidenough: 'هل يدفعون لك ما يكفي؟',
                    yourvoicemattersmakeitheard: 'صوتك مهم ، اجعله مسموعًا.',
                    findoutwhereyoustandamongyourpeers: 'اكتشف مكانتك بين أقرانك.',
                    howdidyougetthisinterview: 'كيف حصلت على هذه المقابلة؟',
                    jobportal: 'بوابة للوظائف',
                    campusplacement: 'التنسيب في الحرم الجامعي',
                    referral: 'الإحالة',
                    walkin: 'Walk-in',
                    companywebsite: 'موقع الشركة',
                    recruitmentconsultant: 'مستشار التوظيف',
                    newspapaperad: 'إعلان جريدة',
                    jobfair: 'معرض الوظائف',
                    approachedbycompany: 'تم التواصل من قبل الشركة',
                    whenwasthisinterview: 'متى كانت هذه المقابلة؟',
                    lessthanamonth: 'أقل من شهر',
                    oneetosixmonthsago: 'منذ 1-6 أشهر',
                    sixtotwelvemonthsago: 'منذ 6-12 شهرًا',
                    morethanayearago: 'منذ أكثر من عام',
                    howlongwastheinterviewprocess: 'كم استغرقت المقابلة؟',
                    lessthantwoweeks: 'أقل من أسبوعين',
                    twotofour: '2-4 أسابيع',
                    fourtosixweeks: '4-6 أسابيع',
                    sixtoeightweeks: '6-8 أسابيع',
                    morethaneightweeks: 'أكثر من 8 أسابيع',
                    howdifficultwastheinterview: 'ما مدى صعوبة المقابلة؟',
                    easy: 'سهل',
                    moderate: 'معتدل',
                    hard: 'صعب',
                    didyougetanoffer: 'هل حصلت على عرض؟',
                    yesijoined: 'نعم ، انضممت إلى الشركة',
                    yesbutideclinedtheoffer: 'نعم ، لكني رفضت العرض',
                    noididntrecieveanoffer: 'لا ، لم أتلق عرضًا',
                    didnthearbackfromcompany: 'لم أتلق أي رد من الشركة',
                    success: 'نجاح',
                    wastherearesumescreeningbeforetheinterview: 'ل كان هناك فحص للسيرة الذاتية قبل المقابلة؟',
                    yes: 'نعم',
                    no: 'لا',
                    whatwasround: 'ما كان الجولة',
                    sharedetails: 'شارك التفاصيل',
                    question: 'السؤال',
                    answer: 'الاجابة',
                    shareadviceforjobseekers: 'شارك النصائح للباحثين عن عمل',
                    shareyourtips: 'شارك بنصائحك',
                    rateoverallinterviewexperience: 'قيم تجربة المقابلة الشاملة',
                    terrible: 'رهيب',
                    poor: 'ضعيف',
                    average: 'متوسط',
                    good: 'جيد',
                    excellent: 'ممتاز',
                    keepanonymous: 'أبقها مجهولة',
                    confirmdelete: 'تأكيد الحذف',
                    areyousureyouwanttodeleterounddetails: 'هل أنت متأكد أنك تريد حذف تفاصيل الجولة؟',
                    salary: 'الراتب',
                    senioritylevel: 'مستوى الأقدمية',
                    yearsofexperience: 'سنوات الخبرة',
                    letsfindout: 'هيا نكتشف',
                    edit: 'تعديل',
                    at: 'في',
                    experience: 'خبرة',
                    recalculate: 'إعادة الحساب',
                    youarepaid: 'يتم الدفع لك',
                    marketvalue: 'القيمة السوقية',
                    yourmarketvalue: 'القيمة السوقية الخاصة بك',
                    year: 'سنة',
                    yourcurrentsalary: 'راتبك الحالي',
                    uploadphotos: 'تحميل الصور',
                    or: 'أو',
                    draganddrop: 'سحب وإسقاط الملفات هنا',
                    photoselected: ' الصورة المختارة',
                    whatisthisphotoabout: 'عن ماذا هذه الصورة',
                    officelocation: 'موقع المكتب',
                    pleasecompletemissingfields: 'الرجاء إكمال الحقول المفقودة',
                    next: 'التالي',
                    submitphotos: 'إرسال الصور',
                    tipsandguidelines: 'نصائح وإرشادات',
                    overallrating: 'تقييم عام',
                    worklifebalance: 'توازن الحياة مع العمل',
                    salaryandbenefits: 'الراتب والمزايا',
                    promotionsappraisal: 'الترقيات / التقييم',
                    jobsecurity: 'الأمن الوظيفي',
                    skilldevelopmentlearning: 'تنمية المهارات / التعلم',
                    worksatisfaction: 'الرضا عن العمل',
                    companyculture: 'ثقافة الشركة',
                    selectmonth: 'اختر الشهر',
                    selectyear: 'حدد السنة',
                    yournamewillremainconfidential: 'سيبقى اسمك سريًا بنسبة 100٪',
                    doyoucurrentlyworkhere: 'هل تعمل هنا حاليا؟',
                    startedworkingon: 'بدأ العمل في',
                    employmentendedon: 'انتهى التوظيف في',
                    ratethecompanyonthefollowingcriteria: 'قيم الشركة على المعايير التالية',
                    whatdoyoulikeaboutworkingatyourcompany: 'ما الذي يعجبك في العمل في شركتك؟',
                    likes: 'Likes',
                    whatdoyoudislikeaboutworkingatyourcompany: 'ما الذي لا يعجبك في العمل في شركتك؟',
                    dislikes: 'Dislikes',
                    doesyourjobrequireyoutotravelforwork: 'هل تتطلب وظيفتك السفر للعمل؟',
                    whatareyourworkingdays: 'ما هي أيام عملك؟',
                    mondaytofriday: 'من الإثنين إلى الجمعة',
                    mondaytosaturday: 'الاثنين الى السبت',
                    alternatesaturdaysoff: 'أيام السبت بالتناوب',
                    rotationalshift: 'وردية العمل الدوراني',
                    alldays: 'كل 7 أيام',
                    other: 'آخر',
                    specifytheexactworkingdays: 'حدد أيام العمل بالضبط',
                    areyourworkinghoursstrictorflexible: 'هل ساعات عملك صارمة أم مرنة؟',
                    strict: 'حازم',
                    flexiable: 'مرن',
                    whatareyourshifttimings: 'ما هي أوقات دوامك؟',
                    starttime: 'وقت البدء',
                    endtime: 'وقت النهاية',
                    gender: 'جنس',
                    male: 'ذكر',
                    female: 'أنثى',
                    permanentworkfromhome: 'عمل دائم من المنزل',
                    workingfromoffice: 'العمل من المكتب',
                    hybrid: 'هجين (العمل من المكتب والمنزل)',
                    unclearatthemoment: 'غير واضح في الوقت الحالي',
                    enterin: 'أدخل ب ٪',
                    yearofincrement: 'سنة الزيادة',
                    employmenttype: 'نوع الوظيفة',
                    interviewroundsandprocess: 'جولات المقابلة',
                    interviewpreparationtips: 'نصائح للتحضير للمقابلة',
                    interviewquestionsprocessandtips: 'أسئلة المقابلة والعملية والنصائح',
                    popularjobprofiles: 'ملفات الوظائف الشعبية',
                    designationjob: 'المسمى الوظيفي',
                    questionsandanswers: 'أسئلة وأجوبة',
                    seequestionsabout: 'انظر الأسئلة حول',
                    more: 'أكثر',
                    moreanswers: 'المزيد من الإجابات',
                    view: 'مشاهدة',
                    promotions: 'الترقيات',
                    categoryratings: 'تصنيفات الفئة',
                    explorereviewsbydepartment: 'استكشف المراجعات حسب القسم',
                    topmentions: 'أعلى الإشارات',
                    inreviews: 'في المراجعات',
                    similarcompanies: 'شركات مماثلة',
                    difficultylevel: 'مستوى الصعوبة',
                    duration: 'مدة',
                    result: 'نتيجة',
                    round: 'الجولة',
                    readmore: 'اقرأ أكثر',
                    workdetails: 'تفاصيل العمل',
                    workpolicy: 'سياسة العمل',
                    workdays: 'أيام العمل',
                    workrelatedtravel: 'السفر المتعلق بالعمل',
                    load: 'حمولة',
                    off: 'خصم',
                    claimfor: 'احصل مقابل',
                    coins: 'عملات',
                    expiringsoon: 'تنتهي قريبا!',
                    salariesinegypt: 'الرواتب في مصر',
                    ratedby: 'تقييم بواسطة',
                    employeesforsalaryandbenefits: ' الموظفين للراتب والمزايا',
                    topdepartmentsat: 'أعلى الإدارات في',
                    designation: 'وظيفة',
                    avarageannualsalary: 'متوسط الراتب السنوي',
                    low: 'أدنى',
                    highest: 'الأعلى',
                    interviewsquestions: 'أسئلة المقابلات',
                    aboutcompany: 'عن الشركة',
                    whichcompanyordesignation: 'أي شركة؟',
                    search: 'بحث',
                    jobsdesignations: 'الوظائف / التعيينات',
                    searchby: 'البحث',
                    discoverbestplacestowork: 'اكتشف أفضل أماكن العمل',
                    bringyourworkplacetolife: 'اجعل مكان عملك ينبض بالحياة',
                    addcompanybenefits: 'أضف مزايا الشركة',
                    highlightyourcompanyperks: 'سلِّط الضوء على مزايا شركتك',
                    companyreviews: 'مراجعات الشركة',
                    readreviews: 'اقرأ التقييمات الخاصة بالشركات',
                    rateyourformercompany: 'قيم شركتك السابقة أو الحالية',
                    browsesalaries: 'تصفح الرواتب',
                    discoversalaries: 'اكتشف رواتب الشركات',
                    areyoupaidfairly: 'هل يدفع لك بشكل عادل',
                    checkyourmarketvalue: 'تحقق من القيمة السوقية الخاصة بك',
                    shareyoursalary: 'شارك راتبك',
                    helpotherjobseekers: 'مساعدة الباحثين عن عمل الآخرين',
                    companyinterviews: 'مقابلات الشركة',
                    readinterviewscompanies: 'اقرأ المقابلات الخاصة بالشركات',
                    addasalary: 'أضف راتب',
                    shareaninterview: 'شارك مقابلة',
                    contributetohelpmillions: 'ساهم في مساعدة الملايين!',
                    contribute: 'ساهم',
                    login: 'تسجيل الدخول',
                    profile: 'الحساب',
                    manageprefrences: 'إدارة التفضيلات',
                    logout: 'تسجيل خروج',
                    edityourprofiledetails: 'تعديل تفاصيل ملف التعريف الخاص بك',
                    areyouastudentorworkingprofessional: 'هل أنت طالب أو عامل محترف؟',
                    studentNeverworked: 'طالب / لم يعمل',
                    iamastudenthaveneverworked: 'أنا طالب / لم أعمل قط',
                    workingprofessional: 'محترف',
                    iamworkinghaveworkedbefore: 'أنا أعمل / لقد عملت من قبل',
                    yourworkexperience: 'خبرتك في العمل',
                    save: 'حفظ',
                    coming: 'آت',
                    soon: 'قريباً',
                    location: 'موقع',
                    industry: 'صناعة',
                    companysize: 'حجم الشركة (الموظفون)',
                    companytype: 'نوع الشركة',
                    listofcompaniesinegypt: 'قائمة الشركات في مصر',
                    uniquecompaniesfound: 'وجدت شركات فريدة',
                    companyrating: 'تصنيف الشركة',
                    andabove: 'و فوق',
                    joinegyptslargestcommunity: 'انضم إلى أكبر مجتمع في مصر',
                    email: 'بريد إلكتروني',
                    password: 'كلمة المرور',
                    firstname: 'الاسم الأول',
                    lastname: 'اسم العائلة',
                    confirmpassword: 'تأكيد كلمة المرور',
                    passwordsdontmatch: 'كلمات المرور غير متطابقة',
                    signup: 'اشتراك',
                    workfromhome: 'العمل من المنزل',
                    gymanasium: 'صالة للألعاب الرياضية',
                    softskillstraining: 'تدريب المهارات الشخصية',
                    freefood: 'طعام مجاني',
                    freetransport: 'النقل المجاني',
                    childcare: 'رعاية الطفل',
                    educationassistance: 'مساعدة التعليم',
                    internationalrelocation: 'النقل الدولي',
                    jobtraining: 'التدريب المهني',
                    teamoutings: 'خروجات الفريق',
                    readcompanyreviewstoavoidcareermistakes: 'اقرأ مراجعات الشركة لاتخاذ خيارات وظيفية أفضل',
                    explorecompanyreviews: 'استكشف مراجعات الشركة',
                    exploresalariesofmorethancompanies: 'استكشف رواتب الشركة وتحقق مما إذا كنت تحصل على رواتب عادلة!',
                    exploresalaries: 'كتشف الرواتب',
                    companywiseinterviewquestions: 'تجارب مقابلة على مستوى الشركة',
                    shareinterviewquestions: 'شارك أسئلة المقابلة',
                    egyptsnoplatform: 'المنصة رقم 1 في مصر',
                    for: 'ل',
                    salaryinsights: 'رؤى صاحب العمل',
                    popularsearches: 'عمليات البحث الشعبية',
                    findcompaniesthatdeserveyou: 'ابحث عن الشركات التي تستحقها!',
                    prepareyournextinterviewwithcorridortalks: 'جهز مقابلتك التالية مع CorridorTalks',
                    egyptsinterviewpreparationplatform: 'منصة مصر الأولى لمشاركة تجربة المقابلات',
                    withKcompanies: 'مع الشركات',
                    crazybuttrueinterviewquestionsfromjobprofiles: 'كن مستعدًا مع تجارب مقابلة حقيقية من المجتمع!',
                    preparefrominterviewquestions: ' استعد من أسئلة المقابلة',
                    shineatyourtechnicalinterviewswithlakhinterviewquestionssparklogo: 'تصفح تجارب المقابلة الحقيقية لوظائف مختلفة وشركات مختلفة!',
                    browsecompaniesbylocation: 'تصفح الشركات حسب الموقع',
                    browsecompaniesbyindustries: ' تصفح الشركات حسب الصناعات',
                    browsebycompanysize: 'تصفح حسب حجم الشركة',
                    basedonnumberofemployees: 'على أساس عدد الموظفين',
                    popularcompanies: 'الشركات الشائعة',
                    getrewardsforhelpingothers: ' احصل على مكافآت لمساعدة الآخرين',
                    yourcoinsbalance: 'رصيد عملاتك',
                    claimed: 'المأخوذة',
                    coinshistory: 'تاريخ العملات',
                    all: 'الكل',
                    earned: 'حصل عليها',
                    spent: 'أنفقت',
                    contributedofficephotos: 'صور المكتب المساهم بها',
                    contributedinterview: 'المقابلات المساهم بها',
                    contributedreview: 'المراجعات المساهم بها',
                    contributedbenefits: 'الفوائد المساهم بها',
                    claimedreward: 'المكافأة المأخوذة',
                    howdocoinswork: 'كيف تعمل العملات?',
                    sharethegoodness: 'شارك السعادة!',
                    price: 'سعر',
                    validtill: 'صالح حتى',
                    stepstoredeem: 'خطوات الاسترداد',
                    tandc: 'الشروط والأحكام',
                    earntoredeem: 'كسب لتخليص',
                    yousave: 'قمت بحفظ',
                    doyouwanttoproceedwiththetransaction: 'هل تريد المضي قدما؟',
                    تابع: 'سيتم خصم العملات من حسابك',
                    proceed: 'تابع',
                    searchandcomparesalaries: 'البحث والمقارنة بين الرواتب',
                    comparesalariesbydesignationsandexperience: 'قارن الرواتب حسب التعيينات والخبرة.',
                    salarycomparison: 'مقارنة الراتب',
                    comparesalariesbetweenhighestpayingcompanies: 'قارن الرواتب بين الشركات الأعلى أجراً',
                    getrelevantsalaryresultsbyselectingyourdepartment: ' احصل على نتائج الرواتب ذات الصلة عن طريق اختيار قسمك',
                    salariesbycompany: 'الرواتب حسب الشركة',
                    enternewpassword: 'أدخل كلمة مرور جديدة',
                    enterannualsalaryexpectation: 'أدخل الراتب السنوي المتوقع',
                    preferredjobprofiles: 'ملفات تعريف الوظائف المفضلة',
                    addyourpreferrredjobprofilestogetrelevantjobsuggestions: 'أضف ملفات تعريف الوظائف المفضلة لديك للحصول على اقتراحات الوظائف ذات الصلة',
                    preferredindustry: 'الصناعة المفضلة',
                    addyourpreferrredindustrieswhereyouwanttowork: 'أضف الصناعات المفضلة لديك حيث تريد العمل',
                    salaryexpectation: 'توقعات الراتب',
                    addyourminimumsalaryexpectationtogetrelevantjobsuggestions: 'أضف الحد الأدنى للراتب المتوقع الخاص بك للحصول على اقتراحات الوظائف ذات الصلة',
                    yourskills: 'مهاراتك',
                    addyourskillstogetrelevantjobsuggestions: 'أضف مهاراتك للحصول على اقتراحات وظيفية ذات صلة',
                    preferredlocations: 'المواقع المفضلة',
                    addyourpreferrredlocationstogetrelevantjobsuggestions: 'أضف المواقع المفضلة لديك للحصول على اقتراحات الوظائف ذات الصلة',
                    manageyourpreferences: 'إدارة تفضيلاتك',
                    updateyourpreferencestogetapersonalisedexperienceoncorridortalks: 'قم بتحديث تفضيلاتك للحصول على تجربة مخصصة على CorridorTalks.',
                    add: 'ضف',
                    enteryourpreferredindustry: 'أدخل مجالك المفضل',
                    enteryourskills: 'أدخل مهاراتك',
                    enteryourpreferredlocations: 'أدخل المواقع المفضلة لديك',
                    enteryourpreferredjobprofiles: 'أدخل ملفات التعريف الوظيفية المفضلة لديك',
                    wantanenhancedexperience: ' هل تريد تجربة محسنة؟',
                    clickhere: 'انقر هنا',
                    companiesyoufollow: 'الشركات التي تتابعها',
                    youhaventfollowedanycompanysofar: 'لم تتابع أي شركة حتى الآن',
                    userinformation: ' معلومات المستخدم',
                    submittedon: 'تم إرساله في',
                    postedon: 'نشر في',
                    byyou: 'بواسطتك',
                    editprofileinfo: 'تعديل معلومات ملف الشخصي',
                    rateyourcompany: 'قيم شركتك',
                    youhaventwrittenanycompanyreviewsofar: 'لم تكتب أي مراجعة لشركة حتى الآن',
                    interviewexperience: 'تجربة المقابلة',
                    submitinterviewquestions: 'إرسال أسئلة المقابلة',
                    youhaventwrittenanyinterviewreviewsofar: 'لم تكتب أي مراجعة مقابلة حتى الآن',
                    salariesshared: 'رواتب مشاركة',
                    submitasalary: 'تقديم راتب',
                    youhaventsubmittedanysalarysofar: 'لم تقدم أي راتب حتى الآن',
                    companyphotos: 'صور الشركة',
                    youhaventsubmittedanycompanyphotosofar: 'لم ترسل أي صورة للشركة حتى الآن',
                    addbenefits: 'أضف الفوائد',
                    companybenefits: 'فوائد الشركة',
                    youhaventsubmittedanycompanybenefitssofar: 'لم تقدم أي مزايا للشركة حتى الآن',
                    contributionsgiven: 'المساهمات المقدمة',
                    review: 'مراجعة',
                    inerview: 'Interview',
                    salary: 'مرتب',
                    averagesalary: 'متوسط ​​الدخل',
                    buildyourcv: 'بناء سيرتك الذاتية',
                    contributeyourinterviewquestions: 'ساهم بأسئلة المقابلة الخاصة بك',
                    shareyoucompanybenefits: 'شارك فوائد شركتك',
                    helpotherjobseekersbysharingyoursalary: 'ساعد الآخرين من خلال مساهمة راتبك',
                    reviewtitle: 'العنوان',
                    personaldevelopment: 'تطوير الذات',
                    managementandleadershipcapabilities: 'القدرات الإدارية والقيادية',
                    diversityandinclusion: 'التنوع والشمول',
                    careergrowth: 'النمو الوظيفي',
                    whatarethestrengths: 'ما هي نقاط القوة',
                    whatarethedevelopmentareas: 'ما هي مجالات التطوير',
                    whatrecommendationswouldyougivetothemanagement: 'ما هي التوصيات التي ستعطيها للإدارة',
                    wouldyourecommendthiscompanytoafriend: 'هل تنصح هذه الشركة لصديق',
                    areyouconsideringleaving: 'هل تفكر في المغادرة',
                    organisationallevel: 'المستوى التنظيمي',
                    intern: 'Intern',
                    nonmanagement: 'Non-management',
                    management: 'Management',
                    seniormanagement: 'Senior management',
                    director: 'Director',
                    wasanofferprovided: 'هل تم تقديم عرض',
                    interviewtitle: 'عنوان المقابلة',
                    ratetherecruitmentprocessinthebelowcategories: 'قيم عملية التوظيف في الفئات أدناه',
                    employerbrandrepresentation: 'تمثيل العلامة التجارية لصاحب العمل',
                    interviewersquestionsandstyle: 'أسئلة وأسلوب المقابلة',
                    lengthoftherecruitmentprocess: 'طول عملية التوظيف',
                    courtesyandprofessionalismduringrecruitmentprocess: 'اللباقة والاحتراف أثناء عملية التوظيف',
                    areyouconsideringreapplyingagain: 'هل تفكر في إعادة التقديم مرة أخرى؟',
                    employmentstatus: 'الحالة الوظيفية',
                    companycultureemployeeengagement: 'ثقافة الشركة ومشاركة الموظفين',
                    worklifebalanceandoverallwellbeing: 'التوازن بين العمل والحياة والصحة العامة',
                    pleaselogintocheckyourredeemedrewards: 'يرجى تسجيل الدخول للتحقق من مكافآتك المستردة.',
                    loginsignup: 'التسجيل فى الموقع',
                    guest: 'Guest',
                    bonusscheme: 'نظام العلاوات',
                    fixedsalary: 'راتب ثابت',
                    whichyearssalaryisit: 'ما هى السنة؟',
                    lastincrement: 'الزيادة الأخيرة',
                    aretherestockoptions: 'هل توجد خيارات للأسهم؟',
                    howmanyshares: 'كم عدد الاسهم؟',
                    totalyearsofexperience: 'مجموع سنوات الخبره',
                    yearsofexperienceinthissenioritylevel: 'سنوات من الخبرة في هذا المستوى من الأقدمية',
                    interviewrounds: 'جولات المقابلة',
                    interviewexperience: 'تجربة المقابلة',
                    monthlysalary: 'راتب شهري',
                    notaddedyet: 'لم يتم الاضافة بعد',
                    searchcompanyreviewsratings: 'بحث عن تقييمات الشركة',
                    readwhatemployeesaresayingaboutcompanies: 'اقرأ ما يقوله الموظفون عن الشركات.',
                    share: 'شارك',
                    viewcomments: 'عرض التعليقات',
                    hidecomments: 'إخفاء التعليقات',
                    benefits: 'فوائد',
                    qanda: 'اسئلة',
                    photos: 'الصور',
                    personalinformation: 'معلومات شخصية',
                    name: 'الاسم',
                    country: 'البلد',
                    phonenumber: 'رقم التليفون',
                    socialmedialink: 'رابط وسائل التواصل الاجتماعي',
                    careersummary: 'ملخص الوظيفي',
                    education: 'تعليم',
                    schoolname: 'اسم المدرسة',
                    college: 'جامعة',
                    major: 'تخصص',
                    gpa: 'GPA',
                    doyoucurrentlystudyhere: 'هل تدرس حاليا هنا؟',
                    startedon: 'بدأت في',
                    endedon: 'انتهيت في',
                    workexperiencecareerachievements: 'خبرة العمل والإنجازات المهنية',
                    title: 'عنوان',
                    job: 'الوظيفة',
                    summaryofasksandachievements: 'ملخص المهام والإنجازات',
                    entityname: 'اسم الجهة',
                    trainingtitle: 'عنوان التدريب',
                    professionaltraining: 'تدريب احترافي',
                    training: 'تمرين',
                    communityservice: 'خدمة المجتمع',
                    summaryofdonetasks: 'ملخص المهام المنجزة',
                    date: 'تاريخ',
                    internshipsandfellowships: 'التدريب والزمالات',
                    internship: 'التدريب',
                    position: 'موضع',
                    tasksdone: 'تم إنجاز المهام',
                    enterskill: 'أدخل المهارة',
                    languages: 'اللغات',
                    youhavenocvsyet: 'لم تطالب بهذه المكافأة بعد - ساهم وادعي الآن لبناء سيرتك الذاتية بطريقة سهلة ومهنية!',
                    buildyourcvnow: 'قم ببناء سيرتك الذاتية الآن',
                    cvtitle: 'عنوان السيرة الذاتية',
                    buildanotercv: 'بناء سيرة ذاتية أخرى',
                    delete: 'مسح',
                    show: 'عرض',
                    month: 'شهر',
                    strengths: 'نقاط القوة',
                    developmentareas: 'مناطق التطوير',
                    monthlysalary: 'الراتب الشهري الصافي (EGP)',
                    wedonthaveinfoaboutthiscompanyyet: 'ليس لدينا معلومات عن هذه الشركة حتى الآن',
                    home: 'الرئسية',
                    browsecompanyreviews: 'تصفح مراجعات الشركة',
                    browsecompanyinterviews: 'تصفح مقابلات الشركة',
                    discovercompanies: 'اكتشف الشركات',
                    languageproficiencylevel: 'مستوى إتقان اللغة',
                    language: 'اللغة',
                    beginner: 'مبتدئ',
                    intermediate: 'متوسط',
                    advanced: 'متقدم',
                    careergrowth: 'النمو الوظيفي',
                    native: 'اللغة الأم',
                    download: 'تحميل',
                    tasks: 'مهام',
                    task: 'مهمة',
                    extracurricularactivities: 'نشاطات خارجية',
                    areyoureferredbyanyofourinterns: 'هل لديك رمز إحالة؟',
                    refcode: 'الرمز',
                    personaldevlopement: 'تطوير الذات',
                    infounavailable: 'معلومات غير متوفرة',
                    addanswer: 'أضف إجابة',
                    successcontribution: 'شكرًا لك على إرسالك ، تتم مراجعة مساهمتك وستتم الموافقة عليها قريبًا جدًا!',
                    recommendedtofriend: 'سيوصي للأصدقاء',
                    optional: 'اختياري',
                    alldepartments: 'جميع الإدارات',
                    followus: 'تابعنا',
                    rejectedbenefits: 'تم رفض مساهمة المزايا الخاصة بك',
                    acceptedbenefits: 'تم قبول مساهمتك في المنافع',
                    pendingbenefits: 'مساهمة المنفعة الخاصة بك معلقة',
                    rejectedsalary: 'تم رفض مساهمة الراتب الخاص بك',
                    acceptedsalary: 'تم قبول مساهمة راتبك',
                    pendingsalary: 'مساهمة راتبك معلقة',
                    sharetheperksandbenefitsofyourcompany: 'شارك مزايا شركتك وفوائدها',
                    youhavenotclaimedthisrewardandclaimnowtohaveyourresumereviewedbyprofessionals:
                        'لم تطالب بهذه المكافأة حتى الآن - ساهم وادعي الآن لمراجعة سيرتك الذاتية بواسطة متخصصي الموارد البشرية!',
                    requesthrReviewforyourcv: 'طالب مراجعة الموارد البشرية لسيرتك الذاتية',
                    notifications: 'إشعارات',
                    rejectedreview: 'تم رفض مساهمة المراجعة الخاصة بك',
                    acceptedreview: 'تم قبول مساهمتك في المراجعة',
                    pendingreview: 'مساهمتك في المراجعة معلقة',
                    rejectedinterview: 'تم رفض مساهمتك الخاصة بالمقابلة',
                    acceptedinterview: 'تم قبول مساهمتك الخاصة بالمقابلة',
                    pendinginterview: 'مساهمتك الخاصة بالمقابلة معلقة',
                },
            },
            langdetect: '',
            setlang: this.setlang,
        };
    }

    setlang = (key) => {
        const cookies = new Cookies();
        cookies.set('sitelang', key, { path: '/' });
        this.setState({
            langdetect: key,
        });
        window.location.reload();
    };

    componentDidMount() {
        const cookies = new Cookies();
        var lastlangselected = cookies.get('sitelang');
        if (lastlangselected == 'en') {
            this.setState({
                langdetect: 'en',
            });
            document.body.setAttribute('dir', 'ltr');
        } else if (lastlangselected == 'ar') {
            this.setState({
                langdetect: 'ar',
            });
            document.body.setAttribute('dir', 'rtl');
        } else {
            this.setState({
                langdetect: 'en',
            });
            document.body.setAttribute('dir', 'ltr');
        }
    }

    render() {
        var setlang = this.state.setlang;
        var lang = '';

        if (this.state.langdetect == 'en') {
            lang = this.state.lang.en;
            document.body.setAttribute('dir', 'ltr');
        } else if (this.state.langdetect == 'ar') {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        } else {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        }

        var langdetect = this.state.langdetect;
        return <LanguageContext.Provider value={{ lang, setlang, langdetect }}>{this.props.children}</LanguageContext.Provider>;
    }
}

export const LanguageContextConsumer = LanguageContext.Consumer;
