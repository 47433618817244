import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

const API = () => {
    const axiosheaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
    const axiosheadersfiles = {
        'Content-Type': 'multipart/form-data',
    };
    const serverbaselinkfunc = () => {
        var serverbaselinktemp = 'https://medgo.herokuapp.com';
        // var serverbaselinktemp = 'http://0.0.0.0:8000';
        // var serverbaselinktemp = 'http://192.168.1.107:8000';
        // var serverbaselinktemp = 'http://localhost:8000';
        // var serverbaselinktemp = 'http://192.168.1.120:8000';
        return serverbaselinktemp;
    };
    const Checkauth_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/UserAuthorizationChecker/',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const FetchRoomsQuery_API = async (axiosdata) => {
        var tempaxiosdata = { ...axiosdata };
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'GET',
            url: serverbaselinkfunc() + '/api/brooms/allrooms/',
            headers: axiosheaders,
            params: tempaxiosdata,
        });
        return axiosfetch;
    };
    const serverbaselink_images = () => {
        return 'https://ik.imagekit.io/iup2hbhfs/';
        // return 'https://medgo2.s3.amazonaws.com/r';
    };
    const FetchStates_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'get',
            url: serverbaselinkfunc() + '/api/countries/fetchstates',
            headers: axiosheaders,
            params: axiosdata,
        });
        return axiosfetch;
    };
    const Fetchcities_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'get',
            url: serverbaselinkfunc() + '/api/countries/fetchcities',
            headers: axiosheaders,

            params: axiosdata,
        });
        return axiosfetch;
    };
    const FetchCountries_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'get',
            url: serverbaselinkfunc() + '/api/countries/fetchcountries',
            headers: axiosheaders,

            params: axiosdata,
        });
        return axiosfetch;
    };
    const FetchSpecializations_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'get',
            url: serverbaselinkfunc() + '/api/specizliations/fetchspecializations/',
            headers: axiosheaders,

            params: axiosdata,
        });
        return axiosfetch;
    };
    const Fetchrelatedrooms_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'get',
            url: serverbaselinkfunc() + '/api/brooms/relatedrooms',
            headers: axiosheaders,
            params: axiosdata,
        });
        return axiosfetch;
    };
    const FetchRelatedRoomsInTheSameArea_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'get',
            url: serverbaselinkfunc() + '/api/brooms/relatedrooms_area',
            headers: axiosheaders,
            params: axiosdata,
        });
        return axiosfetch;
    };
    const FetchRoomInfo_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'get',
            url: serverbaselinkfunc() + '/api/brooms/roominfo/',
            headers: axiosheaders,
            params: axiosdata,
        });
        return axiosfetch;
    };
    const Login_API = async (axiosdata) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/login/',
            headers: axiosheaders,
            data: axiosdata,
        });
        return axiosfetch;
    };
    const Signup_API = async (axiosdata) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/signup/',
            headers: axiosheaders,
            data: axiosdata,
        });
        return axiosfetch;
    };
    const signupcheckers_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/signupcheckers/',
            headers: axiosheaders,
            data: axiosdata,
        });
        return axiosfetch;
    };
    const Roomslotgetter_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'get',
            url: serverbaselinkfunc() + '/api/brooms/roomslots/',
            headers: axiosheaders,
            params: axiosdata,
        });
        return axiosfetch;
    };
    function timeConvert(time) {
        // Check correct time format and split into components
        const [hours, minutes] = time.split(':');
        const formattedTime = new Date();
        formattedTime.setHours(parseInt(hours), parseInt(minutes));
        return formattedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
    const dateformatter = (date) => {
        // var string = date;
        if (date != undefined && date != null && date.length != 0) {
            var datePart = date?.match(/\d+/g);
            if (Array.isArray(datePart)) {
                var year = datePart[0].substring(0); // get only two digits
                var month = datePart[1];
                var day = datePart[2];
            } else {
                var year = '',
                    month = '',
                    day = '';
            }

            return day + '-' + month + '-' + year;
        } else {
            return '';
        }
        // return string;
    };
    const BookRoom_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/brooms/bookroom/',
            headers: axiosheaders,
            data: axiosdata,
        });
        return axiosfetch;
    };
    const FetchUserRoomReservations_API = async (axiosdata, hasfiles = false) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'get',
            url: serverbaselinkfunc() + '/api/brooms/FetchUserRoomReservations/',
            headers: axiosheaders,
            params: axiosdata,
        });
        return axiosfetch;
    };
    const ForgotPassword_API = async (axiosdata) => {
        axios.defaults.withCredentials = true;
        const axiosfetch = axios({
            method: 'post',
            url: serverbaselinkfunc() + '/api/verify/emailverify',
            headers: axiosheaders,
            data: axiosdata,
        });
        return axiosfetch;
    };
    return {
        Checkauth_API,
        FetchRoomsQuery_API,
        serverbaselink_images,
        FetchStates_API,
        Fetchcities_API,
        FetchCountries_API,
        FetchSpecializations_API,
        Fetchrelatedrooms_API,
        FetchRelatedRoomsInTheSameArea_API,
        FetchRoomInfo_API,
        Login_API,
        Signup_API,
        signupcheckers_API,
        Roomslotgetter_API,
        timeConvert,
        dateformatter,
        BookRoom_API,
        FetchUserRoomReservations_API,
        ForgotPassword_API,
    };
};
export default API;
