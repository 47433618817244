import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from './LanguageContext';
import API from './components/API/API';
import { useQuery, useMutation, useInfiniteQuery } from 'react-query';
import Cookies from 'universal-cookie';
import { NotificationManager } from 'react-notifications';

export const Contexthandlerscontext = React.createContext();

export const Contexthandlerscontext_provider = (props) => {
    const cookies = new Cookies();
    let history = useHistory();
    const { FetchRoomsQuery_API, Checkauth_API } = API();
    const { lang, langdetect } = useContext(LanguageContext);
    const [scroll, setScroll] = useState(false);
    const [pageActiveTitle, setPageActiveTitle] = useState(undefined);
    const [fetchAllroomspayloadobjContext, setfetchAllroomspayloadobjContext] = useState({
        business_id: '',
        scheduletype: 'all',
        pricerangemin: 'all',
        pricerangemax: 'all',
        specialities: [],
        timefilter: 'all',
        fromdate: 'all',
        todate: 'all',
        lat: '',
        long: '',
        page: 1,
        radius: 2,
        search: '',
    });
    const FetchRoomsQueryContext = useInfiniteQuery(
        ['FetchRoomsQuery_API' + JSON.stringify(fetchAllroomspayloadobjContext)],
        () => FetchRoomsQuery_API({ filter: JSON.stringify(fetchAllroomspayloadobjContext) }),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        },
    );
    const dateformatter = (date) => {
        // var string = date;

        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', hour12: true };
        return new Date(date).toLocaleDateString(undefined, options);
    };
    const dateformatter2 = (date) => {
        // var string = date;

        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options);
    };

    const dateformatter3 = (date) => {
        // var string = date;

        const options = { year: 'numeric', month: 'short' };
        return new Date(date).toLocaleDateString(undefined, options);
    };
    const fetchAuthorizationQueryContext = useQuery(['fetchAuthorization_API'], () => Checkauth_API(), {
        keepPreviousData: true,
        staleTime: Infinity,
    });
    return (
        <Contexthandlerscontext.Provider
            value={{
                scroll,
                setScroll,
                dateformatter,
                dateformatter2,
                dateformatter3,
                FetchRoomsQueryContext,
                fetchAllroomspayloadobjContext,
                setfetchAllroomspayloadobjContext,
                pageActiveTitle,
                setPageActiveTitle,
                fetchAuthorizationQueryContext,
            }}
        >
            {props.children}
        </Contexthandlerscontext.Provider>
    );
};
